import ThemisInput from "@/components/shared/input"

export default {
  name      : "WorkflowStatusMigration",
  components: {
    ThemisInput
  },
  data() {
    return {
      migrationStatuses: [],
      selectedStatuses : {}
    }
  },
  props: {
    pIssueTypes             : [],
    pNewWorkflowStatuses    : Array,
    pCurrentWorkflowStatuses: Array
  },
  computed: {
    fieldName() {
      return this.$t("1562")
    },
    allSelected() {
      const nonEmptyStatusCount = Object.values(this.selectedStatuses).filter(status => {
        return status !== null && status !== undefined
      }).length

      const totalStatusCount = this.pCurrentWorkflowStatuses
        .filter(statuses => statuses !== null)
        .reduce((count, statuses) => count + statuses.length, 0)

      return nonEmptyStatusCount === totalStatusCount
    },
    workflowsHelpCenterUrl() {
      return process.env.VUE_APP_WORKFLOWS_HELP_URL
    }
  },
  methods: {
    handleCancelStatusMigrationDialog() {
      this.migrationStatuses = []
      this.$emit("closeDialog")
    },
    migrateStatus() {
      for (const [key, toStatusId] of Object.entries(this.selectedStatuses)) {
        const [issueTypeIndex, statusIndex] = key.split("-")
        if (!this.migrationStatuses[issueTypeIndex]) {
          this.$set(this.migrationStatuses,
            issueTypeIndex,
            {
              issueTypeId: this.pIssueTypes[issueTypeIndex].id,
              migrations : []
            })
        }
        this.migrationStatuses[issueTypeIndex].migrations[statusIndex] = {
          fromStatusId: this.pCurrentWorkflowStatuses[this.pIssueTypes[issueTypeIndex].id][statusIndex].id,
          toStatusId
        }
      }
      this.$emit("migrateStatus", this.migrationStatuses)
    }
  }
}