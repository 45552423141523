import { getHeadersForTable, getFootersForTable } from "@/utils/table"
import ThemisInput from "@/components/shared/input"
import { mapGetters, mapActions, mapMutations } from "vuex"
import {
  TABLE_NAMES,
  MAX_CHARACTER_LIMIT,
  BOOLEAN,
  ISSUE_TYPES,
  ISSUE_STATUS_MIGRATION_POLL_INTERVAL
} from "@/constants"
import ThemisWorkflowStatusMigration from "@/components/workflow-association/status-migration"

export default {
  name      : "Workflows",
  components: {
    ThemisInput,
    ThemisWorkflowStatusMigration
  },
  data: () => ({
    migrateScreen              : false,
    showAddWorkflowDialog      : false,
    showAssociateWorkflowDialog: false,
    showStatusMigrationDialog  : false,
    workflowNameCharacterLimit : MAX_CHARACTER_LIMIT.WORKFLOW_NAME,
    isWorkflowNameDuplicate    : false,
    workflow                   : {
      name: null
    },
    workflowName          : null,
    issueTypeName         : null,
    workflowToBeAssociated: {
      id: null
    },
    issueTypeToBeAssociated: {
      issueType: null
    },
    newWorkflowStatuses       : [],
    currentWorkflowStatuses   : [],
    issueStatusPollingInterval: null,
    pollForAllIssueTypes      : false,
    pollForCase               : false,
    pollForTask               : false
  }),
  beforeDestroy() {
    this.clearIssueStatusPollingInterval()
  },
  methods: {
    ...mapActions({
      addWorkflow             : "workflows/addWorkflow",
      addWorkflowAssociation  : "workflowAssociations/addWorkflowAssociation",
      loadWorkflowAssociations: "workflowAssociations/loadWorkflowAssociations",
      notify                  : "shared/notify"
    }),
    ...mapMutations({
      setWorkflowAddError           : "workflows/setWorkflowAddError",
      setWorkflowAssociationAddError: "workflowAssociations/setWorkflowAssociationAddError"
    }),
    handleCancelAddWorkflow() {
      this.workflow.name         = null
      this.showAddWorkflowDialog = false
    },
    handleAddWorkflow() {
      this.addWorkflow({
        name: this.workflow.name
      })
    },
    handleCancelAssociateWorkflowDialog() {
      if (this.showStatusMigrationDialog === false) {
        this.workflowToBeAssociated.id         = null
        this.issueTypeToBeAssociated.issueType = null
      }
      this.showAssociateWorkflowDialog = false
    },
    handleShowStatusMigrationDialog() {
      if (!this.workflowAssociationAddError) {
        this.showStatusMigrationDialog = true
      }
    },
    handleCancelStatusMigrationDialog() {
      this.showStatusMigrationDialog         = false
      this.workflowToBeAssociated.id         = null
      this.issueTypeToBeAssociated.issueType = null
    },
    getNewWorkflowStatuses(workflowId) {
      const newWorkflow        = this.workflows.find(workflow => workflow.id === workflowId)
      this.newWorkflowStatuses = newWorkflow.statuses
    },
    getCurrentWorkflowStatuses(issueTypes) {
      const issueTypeStatuses = []
      for (const issueType of issueTypes) {
        const workflowAssociation       = this.workflowAssociations.find(workflowAssociation =>
          workflowAssociation.issueTypeId === issueType.id
        )
        const currentWorkflow           = this.workflows
          .find(workflow => workflow.id === workflowAssociation.workflowId)
        issueTypeStatuses[issueType.id] = currentWorkflow.statuses
      }
      this.currentWorkflowStatuses = issueTypeStatuses
    },
    handleAssociateWorkflow() {
      if (this.workflowAssociationAddError) {
        return
      }
      this.getCurrentWorkflowStatuses(this.issueTypeToBeAssociated.issueType)
      this.getNewWorkflowStatuses(this.workflowToBeAssociated.id)
      this.handleCancelAssociateWorkflowDialog()
    },
    async handleStatusMigration(migrationStatuses) {
      this.migrateScreen = true
      const payload      = []
      for (const migrationStatus of migrationStatuses) {
        payload.push({
          issueTypeId: migrationStatus.issueTypeId,
          workflowId : this.workflowToBeAssociated.id,
          migrations : migrationStatus.migrations
        })
      }
      await this.addWorkflowAssociation(payload)
      this.associateWorkflowAndIssueTypeName()
      this.showStatusMigrationDialog         = false
      this.workflowToBeAssociated.id         = null
      this.issueTypeToBeAssociated.issueType = null
      if (payload.length > 1) {
        this.pollForAllIssueTypes = true
        return
      } else {
        if (payload[0].issueTypeId === 1) {
          this.pollForCase = true
          return
        } else {
          this.pollForTask = true
          return
        }
      }

    },
    associateWorkflowAndIssueTypeName() {
      if (this.migrateScreen) {
        if (this.issueTypeToBeAssociated.issueType && this.issueTypeToBeAssociated.issueType.length > 1) {
          this.issueTypeName = ISSUE_TYPES[0].name + "," + ISSUE_TYPES[1].name
        } else if (this.issueTypeToBeAssociated.issueType[0].name === ISSUE_TYPES[0].name) {
          this.issueTypeName = ISSUE_TYPES[0].name
        } else {
          this.issueTypeName = ISSUE_TYPES[1].name
        }
        this.workflowName = this.workflows.find(
          workflow => workflow.id === this.workflowToBeAssociated.id)?.name
      }
    },
    clearIssueStatusPollingInterval() {
      if (this.issueStatusPollingInterval) {
        clearInterval(this.issueStatusPollingInterval)
        this.issueStatusPollingInterval = null
      }
    },
    handleIssueTypeInputOnInputEvent(onBlur) {
      onBlur()
      if (this.workflowToBeAssociated.id) {
        const issueTypeIdsToBeAssociated = this.issueTypeToBeAssociated.issueType.map(issueType => issueType.id)
        const isWorkflowAssociated       = this.workflowAssociations.find(workflowAssociation =>
          issueTypeIdsToBeAssociated.includes(workflowAssociation.issueTypeId)
          && workflowAssociation.workflowId === this.workflowToBeAssociated.id)
        if (isWorkflowAssociated) {
          this.workflowToBeAssociated.id = null
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      workflows                  : "workflows/workflows",
      isAddingWorkflow           : "workflows/isAddingWorkflow",
      isWorkflowAdded            : "workflows/isWorkflowAdded",
      workflowAddError           : "workflows/workflowAddError",
      issueTypes                 : "issueTypes/issueTypes",
      isWorkflowsEnabled         : "configurations/isWorkflowsEnabled",
      workflowAssociations       : "workflowAssociations/workflowAssociations",
      workflowAssociationAddError: "workflowAssociations/workflowAssociationAddError"
    }),
    workflowsHelpCenterUrl() {
      return process.env.VUE_APP_WORKFLOWS_HELP_URL
    },
    headersForTable() {
      return getHeadersForTable(TABLE_NAMES.WORKFLOWS, this.$t.bind(this))
    },
    footersForTable() {
      return getFootersForTable(TABLE_NAMES.WORKFLOWS, this.$t.bind(this))
    },
    workflowsForTable() {
      return this.workflows.map(workflow => {
        const workflowAssociation = this.workflowAssociations.find(workflowAssociation =>
          workflowAssociation.workflowId === workflow.id
        )
        const issueType           = workflowAssociation
          ? this.issueTypes.find(issueType => issueType.id === workflowAssociation.issueTypeId)?.name
          : ""

        return {
          id       : workflow.id,
          name     : workflow.name,
          issueType: issueType
        }
      })
    },
    itemsForIssueTypeDropdown() {
      return this.issueTypes
        .map(issueType => ({
          name: issueType.name,
          id  : issueType.id
        }))
    },
    itemsForWorkflowDropdown() {
      const associatedWorkflowIds = []
      if (this.issueTypeToBeAssociated.issueType?.length) {
        const issueTypeIdsToBeAssociated = this.issueTypeToBeAssociated.issueType.map(issueType => issueType.id)
        associatedWorkflowIds.push(...this.workflowAssociations
          .filter(workflowAssociation => issueTypeIdsToBeAssociated.includes(workflowAssociation.issueTypeId))
          .map(workflowAssociation => workflowAssociation.workflowId)
        )
      }

      return this.workflows
        .filter(workflow => !associatedWorkflowIds.includes(workflow.id))
        .map(workflow => ({
          name: workflow.name,
          id  : workflow.id
        }))
    },
    isMigrationCompleted() {
      if (this.pollForAllIssueTypes) {
        for (const workflowAssociation of this.workflowAssociations) {
          if (!workflowAssociation.migrationComplete) {
            return BOOLEAN.TRUE
          }
        }
        this.migrateScreen = false
        return BOOLEAN.FALSE
      } else if (this.pollForCase) {
        const caseWorkflowAssociation = this.workflowAssociations.find(workflowAssociation =>
          workflowAssociation.issueTypeId === 1
        )
        if (caseWorkflowAssociation?.migrationComplete) {
          this.migrateScreen = false
          return BOOLEAN.FALSE
        }
        return BOOLEAN.TRUE
      } else if (this.pollForTask) {
        const taskWorkflowAssociation = this.workflowAssociations.find(workflowAssociation =>
          workflowAssociation.issueTypeId === 2
        )
        if (taskWorkflowAssociation?.migrationComplete) {
          this.migrateScreen = false
          return BOOLEAN.FALSE
        }
        return BOOLEAN.TRUE
      }
    },
    isAssociateWorkflowButtonDisabled() {
      return !this.workflowToBeAssociated.id ||
      !this.issueTypeToBeAssociated.issueType ||
      !this.issueTypeToBeAssociated.issueType.length
    }
  },
  watch: {
    "workflow.name": {
      handler: function() {
        if (this.isWorkflowNameDuplicate) {
          this.setWorkflowAddError(null)
        }
      }
    },
    workflowAddError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue?.field === "name" && newValue?.type === "duplicate") {
          this.isWorkflowNameDuplicate = true
        } else {
          this.isWorkflowNameDuplicate = false
        }
      }
    },
    isWorkflowAdded: {
      handler: function(newValue) {
        if (newValue) {
          this.showAddWorkflowDialog = false
          this.workflow.name         = null
        }
      }
    },
    "workflowToBeAssociated.id": {
      handler: function(newValue) {
        if (!newValue) {
          this.setWorkflowAssociationAddError({ field: "workflowToBeAssociated.id", type: "required" })
        } else {
          this.setWorkflowAssociationAddError(null)
        }
      },
      immediate: true
    },
    "issueTypeToBeAssociated.issueType": {
      handler: function(newValue) {
        if (!newValue) {
          this.setWorkflowAssociationAddError({ field: "issueTypeToBeAssociated.issueType", type: "required" })
        } else {
          this.setWorkflowAssociationAddError(null)
        }
      },
      immediate: true
    },
    migrateScreen: {
      handler: function(newValue) {
        if (newValue === BOOLEAN.TRUE) {
          this.issueStatusPollingInterval = setInterval(() => {
            this.loadWorkflowAssociations()
          }, ISSUE_STATUS_MIGRATION_POLL_INTERVAL)
          this.notify({
            type: "success",
            text: "1567"
          })
        } else {
          this.clearIssueStatusPollingInterval()
          this.pollForAllIssueTypes = false
          this.pollForCase          = false
          this.pollForTask          = false
          this.notify({
            type: "success",
            text: "1568"
          })
        }
      }
    }
  }
}